import React, { useMemo, useState } from "reactn";
import { Col, Row, Button, Label } from "reactstrap";
import { withRouter } from "react-router";
import { Query } from "react-apollo";
import { getCatalog } from "../../queries/Queries";
import { dateFormatter } from "../../utils/Formatters";
import { DateRangePicker } from "react-dates";
import BootstrapTable from "react-bootstrap-table-next";
import paginationFactory from "react-bootstrap-table2-paginator";
import axios from "../../utils/Client";
import fileDownload from "js-file-download";
import Loading from "../../components/Loading/Loading";
import moment from "moment";
moment.locale("en");

const CatalogPricesTableView = ({ history, setShowModal }) => {
  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);
  const [pageNumber, setPageNumber] = useState(1);
  const [pageSize, setPageSize] = useState(10);
  const [sortField, setSortField] = useState("name");
  const [sortOrder, setSortOrder] = useState("asc");
  const [focusedInput, setFocusedInput] = useState(null);

  const handleClick = () => {
    setShowModal(true);
  };

  const handleTableChange = (
    type,
    { page, sizePerPage, sortField, sortOrder }
  ) => {
    setPageNumber(page);
    setPageSize(sizePerPage);
    setSortField(sortField);
    setSortOrder(sortOrder);
  };

  const columns = useMemo(
    () => [
      {
        dataField: "name",
        text: "Name",
        sort: true,
      },
      {
        dataField: "startDate",
        text: "Start Date",
        sort: true,
        formatter: (cell, row) => dateFormatter(row.startDate),
      },
      {
        dataField: "endDate",
        text: "End Date",
        sort: true,
        formatter: (cell, row) => dateFormatter(row.endDate),
      },
      {
        dataField: "status",
        text: "Status",
        sort: true,
      },
      {
        dataField: "urn",
        text: "File",
        sort: true,
        formatter: (cell, row) => (
          <i
            className={"icon ion-android-download"}
            style={{ cursor: "pointer", color: "green", fontSize: "2em" }}
            onClick={(e) => {
              e.preventDefault();
              e.stopPropagation();
              if (typeof REST_URL === "undefined") {
                var REST_URL = "";
              }
              axios
                .get(`${REST_URL}/cdn/cdn/resource/${row.urn}`, {
                  headers: {
                    Authorization: "Bearer " + localStorage.token,
                  },
                  responseType: "blob",
                })
                .then((response) => {
                  fileDownload(response.data, row.name + ".xlsx");
                })
                .catch((err) => console.error(err));
            }}
          />
        ),
      },
    ],
    [history]
  );

  return (
    <div className='organization'>
      <Row className='subheader'>
        <Col className='col-8'>
          <h1>Catalog Prices</h1>
        </Col>
      </Row>
      <div className='page-cnt'>
        <Row>
          <Col>
            <Label for='dateRange' className='mr-2'>
              Period
            </Label>
            <DateRangePicker
              startDate={startDate}
              startDateId='startDate'
              endDate={endDate}
              endDateId='endDate'
              onDatesChange={({ startDate, endDate }) => {
                setStartDate(startDate);
                setEndDate(endDate);
              }}
              focusedInput={focusedInput}
              onFocusChange={(focusedInput) => setFocusedInput(focusedInput)}
              isOutsideRange={() => false}
              displayFormat='MMM/DD/YYYY'
            />
          </Col>
          <Col className='d-flex justify-content-end align-items-end'>
            <Button
              onClick={handleClick}
              className='btn btn-primary pull-right'
              color='primary'
            >
              {" "}
              <i className='icon ion-plus-round'></i>&nbsp;&nbsp; Upload Prices
            </Button>
          </Col>
        </Row>
        <Row>
          <Query
            query={getCatalog}
            variables={{
              pageNumber: pageNumber - 1,
              pageSize,
              where:
                startDate && endDate
                  ? `startDate >= "${startDate.format(
                      "YYYY-MM-DD"
                    )}" and endDate <= "${endDate.format("YYYY-MM-DD")}"`
                  : null,
            }}
          >
            {({ loading, error, data }) => {
              if (loading) return <Loading />;
              if (error) return <p>Error</p>;

              if (!data || !Array.isArray(data.Catalog.content))
                return <p>No Data</p>;

              return (
                <React.Fragment>
                  <Col sm='12' className='mt-4'>
                    <BootstrapTable
                      classes='table-hover'
                      wrapperClasses='table-responsive'
                      bordered={false}
                      keyField='id'
                      data={[...data.Catalog.content]}
                      columns={columns}
                      remote={{
                        filter: true,
                        pagination: true,
                        sort: true,
                      }}
                      sort={{
                        dataField: sortField,
                        order: sortOrder,
                      }}
                      pagination={paginationFactory({
                        page: pageNumber,
                        sizePerPage: pageSize,
                        totalSize: data.Catalog.totalElements,
                        sizePerPageList: [
                          { text: "5", value: 5 },
                          { text: "10", value: 10 },
                          { text: "20", value: 20 },
                        ],
                      })}
                      onTableChange={handleTableChange}
                    />
                  </Col>
                </React.Fragment>
              );
            }}
          </Query>
        </Row>
      </div>
    </div>
  );
};

export default withRouter(CatalogPricesTableView);
